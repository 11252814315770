import React from "react";
import {Link} from "react-router-dom";

import Telegram from "../img/icons/social/telegram.svg"
import Instagram from "../img/icons/social/instagram.svg"
import Whatsapp from "../img/icons/social/whatsapp.svg"
import Support from "../img/icons/social/support.svg"

const SocialPattern = () => (
  <svg
    width="56"
    height="57"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="28"
      cy="28.8286"
      r="27.5"
      fill="white"
      fillOpacity="0.1"
      stroke="url(#paint0_radial_4229_18861)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_4229_18861"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(28 34.2492) rotate(90) scale(118.697 25.7867)"
      >
        <stop stopColor="#C12EFC" />
        <stop offset="1" stopColor="#C12EFC" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default function Socialicon2({className=""}) {

  return (
    <>
    <div className={`social-icon-bx ${className}`}>
      <Link className={`sib-item`} target="_blank" to="##">
        <img src={Telegram} alt="" />
        <SocialPattern />
      </Link>
      <Link className={`sib-item`} target="_blank" to="##">
        <img src={Instagram} alt="" />
        <SocialPattern />
      </Link>
      <Link className={`sib-item`} target="_blank" to="##">
        <img src={Whatsapp} alt="" />
        <SocialPattern />
      </Link>
      <Link className={`sib-item`} target="_blank" to="##">
        <img src={Support} alt="" />
        <SocialPattern />
      </Link>
    </div>
    </>
  );
};
