import React from 'react';
import tpmMeter from "../../img/icons/tpm-meter.png";
import tpmPointer from "../../img/icons/tpm-pointer.png";

export default function MeterChart({ className=" ", src=tpmMeter, percentage, label }) {
  // Calculate the degree based on the percentage (0% = -12deg, 100% = 192deg)
  const degree = -12 + (percentage / 100) * 204;

  return (
    <div className={`meter-chart-bx ${className}`}>
        <img className="mcb-meter-img" src={src} alt="Meter" />
        <div className="mcb-meter-pointer" style={{ transform: `translateX(-50%) rotate(${degree}deg)` }}>
          <img src={tpmPointer} alt="Pointer"/>
        </div>
        {label && 
        <div className="mcb-data-bx">
            <div className="mcb-label">{label}</div>
            <div className="mcb-value">{percentage}%</div>
        </div>
        }
    </div>
  );
}
