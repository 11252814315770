import React, { useState, useRef, useEffect } from 'react';
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import EmptyBox from '../../components/EmptyBox';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import DropItem from '../../components/commonfield/DropItem';
import FlashMessage from '../../components/FlashMessage';

export default function BestAccounts() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();
  
  // State hooks
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [addDataIntoArray, setDataIntoArray] = useState([]);
  const [addsecondDataIntoArray, setSecondDataIntoArray] = useState([]);
  const [isFIlter, setIsFIlter] = useState(0);
  const [activeTab, setActiveTab] = useState(0); // Active tab state for account size
  const [selectedOption, setSelectedOption] = useState('Filter'); // Dropdown filter state

  // Dropdown filter options
  const options = ['Filter', '$5000', '$15000', '$25000', '$50000', '$100000', '$200000'];

  // Handle tab click (for account size filter)
  const handleTabClick = (tabName) => {
    setIsFIlter(1);
    setActiveTab(tabName); // Set active tab to the clicked tab
  };

  // Handle dropdown option change (filtering)
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    const sizeMap = {
      '$5000': 5000,
      '$15000': 15000,
      '$25000': 25000,
      '$50000': 50000,
      '$100000': 100000,
      '$200000': 200000,
    };
    const size = sizeMap[option] || 0; // Default to 0 (All) if "Filter" is selected
    setActiveTab(size);
    setIsFIlter(1); // Trigger filter when dropdown is changed
  };

  // Fetch data based on selected filters (activeTab and isFIlter)
  const getBestActiveProfitData = async () => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + 'get-best-account', {
        user_id: state.userData.id,
        token: token,
        account_size: activeTab,
        is_filter: isFIlter,
      });

      if (response.data.success == '0') {
        setDataIntoArray(response.data.data);
        if (activeTab === 0) {
          setSecondDataIntoArray(response.data.data);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  // Fetch data when either activeTab or isFIlter changes
  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            await getBestActiveProfitData(); // Trigger the data fetch when tab or filter changes
            setDataLoaded(true);
          } catch (error) {
            console.error('Failed to fetch data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    } else if (isFIlter === 1) {
      // Trigger data fetching when filter or tab changes
      fetchData();
    }
  }, [token, activeTab, isFIlter]); // Add activeTab and isFIlter to dependencies

  return (
    <div className="col-12 mt-3">
      <div className="common-box cb-all-none">
        <div className="common-box-heading cbh-small">Best Accounts In Profit</div>

        {/* Dropdown Filter */}
        <div className="leaderboard-filter">
          <DropItem
            extraIcon={true}
            options={options}
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </div>

        {/* Tab Filter */}
        <div className="common-tabs-bx ctb-style-2 d-none">
          <div className={`ctb-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
            All
          </div>
          <div className={`ctb-item ${activeTab === 5000 ? 'active' : ''}`} onClick={() => handleTabClick(5000)}>
            $5,000
          </div>
          <div className={`ctb-item ${activeTab === 15000 ? 'active' : ''}`} onClick={() => handleTabClick(15000)}>
            $15,000
          </div>
          <div className={`ctb-item ${activeTab === 25000 ? 'active' : ''}`} onClick={() => handleTabClick(25000)}>
            $25,000
          </div>
          <div className={`ctb-item ${activeTab === 50000 ? 'active' : ''}`} onClick={() => handleTabClick(50000)}>
            $50,000
          </div>
          <div className={`ctb-item ${activeTab === 100000 ? 'active' : ''}`} onClick={() => handleTabClick(100000)}>
            $100,000
          </div>
          <div className={`ctb-item ${activeTab === 200000 ? 'active' : ''}`} onClick={() => handleTabClick(200000)}>
            $200,000
          </div>
        </div>

        {/* Table */}
        <div className="table-responsive custom-table-responsive tr-leaderboard mt-3">
          <table className="custom-table off-tr-bg ct-leaderboard ">
            <thead>
              <tr>
                <th className="text-center" style={{ width: '70px' }}>Rank</th>
                <th>User</th>
                <th>Account Size</th>
                <th>Profit</th>
                <th>Profit %</th>
              </tr>
            </thead>
            <tbody>
              {(addDataIntoArray && addDataIntoArray.length > 0) ? (
                addDataIntoArray.map((account, index) => (
                  <tr key={index}>
                    <td>
                      <img className='rank-img' src={index === 0 ? Img.Rank1 : index === 1 ? Img.Rank2 : index === 2 ? Img.Rank3 : index === 3 ? Img.Rank4: index === 4 ? Img.Rank5 : index === 5 ? Img.Rank6 : index === 6 ? Img.Rank7 : index === 7 ? Img.Rank8 : index === 8 ? Img.Rank9 : index === 9 ? Img.Rank10 : Img.Rank1 } alt="" />
                    </td>
                    <td>
                      <div className="td-user">
                        <div className="tdu-img-bx">
                          <img className="user-img" src={account.thumb_url || Img.Avatar1} alt="" />
                        </div>
                        <div>
                          <div className="user-name">{account.first_name} {account.last_name}</div>
                          {account.country && <span className="user-country">{account.country}</span>}
                        </div>
                      </div>
                    </td>
                    <td>{"$" + account.account_size}</td>
                    <td>{"$" + account.profit}</td>
                    <td><span className="td-status td-s-profit"><Icons.RightArrowSvg /> {account.profit_percentage + "%"}</span></td>
                    <div className="ct-l-bg"></div>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    <EmptyBox className="empty-div-bx" eh="Data not found" esh="Try adjusting filters to get results." />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
