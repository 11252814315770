import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Icons from '../../components/icons';
import { ThemeMode } from '../../context/ThemeMode';

const ArcGauge = ({ value, otherValue }) => {
    const settings = { value, animate: true };
    const { isDarkMode } = ThemeMode();

    // Function to render gradient definitions
    const GradientDefinitions = () => (
        <svg style={{ position: 'absolute', width: 0, height: 0 }}>
            <defs>
                {/* Custom Radial Gradient Definition */}
                <radialGradient id="radialGradientArc" fx="84.26%" fy="48.55%" cx="50%" cy="50%" r="57.13%" gradientTransform="scale(1.5)">
                    <stop offset="0%" stopColor="#151419" />
                    <stop offset="100%" stopColor="#C12EFC" />
                </radialGradient>

                {/* Striped Pattern Definition */}
                <pattern id="stripedPattern" width="10" height="10" patternUnits="userSpaceOnUse" patternTransform="rotate(33)">
                    <rect width="10" height="10" fill={isDarkMode ? '#2A1F2F' : '#d4d7e080'} />
                    <rect width="3" height="10" fill={isDarkMode ? '#FFFFFF1A' : '#ffffff4d'} />
                </pattern>
            </defs>
        </svg>
    );

    return (
        <div className="arc-item common-pe-none">
            <div className="arc-gauge-bx">
                <Gauge
                    {...settings}
                    cornerRadius="50%"
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            visibility: 'hidden',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: `url(#radialGradientArc)`,
                            stroke: '#C12EFC',
                            strokeWidth: value === 0 ? 0 : 1,
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                            fill: `url(#stripedPattern)`,
                        },
                    }}
                />

                <GradientDefinitions />

                <div className="agb-center">
                    {otherValue && <div className="arc-other-value">{otherValue}</div>}
                    <div className="arc-value">{value}%</div>
                </div>
            </div>
        </div>
    );
};

export default ArcGauge;
