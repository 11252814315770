import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Breadcrumb from '../../components/Breadcrumb';
import CalendarNavigation from '../../components/CalendarNavigation';
import DropItem from '../../components/commonfield/DropItem';
import PredictionCard from './PredictionCard';
import CloseIcon from '../../components/CloseIcon';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import EmptyBox from '../../components/EmptyBox';
import Loaders from '../../components/Loader';
import { startOfWeek, endOfWeek, format } from 'date-fns'; // Correctly importing format



// Date helper functions
const parseDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC', // Ensure UTC time zone is used
  });
};


const formatDateForApi = (date) => {
  const formattedDate = new Date(date);
  return formattedDate.toISOString().split('T')[0];
};

// const formatDateForDisplay = (isoDate) => {
//   const date = new Date(isoDate);
//   return date.toLocaleDateString('en-US', {
//     weekday: 'long',
//     day: '2-digit',
//     month: 'short',
//     year: 'numeric',
//   });
// };

const formatDateForDisplay = (isoDate) => {
  const date = new Date(isoDate);
  // Get the formatted date string
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  // Remove the comma from the weekday name
  return formattedDate.replace(',', ''); // This will remove the comma after the weekday
};

// Function to get the start date of the week (Sunday or Monday based on locale)
const getStartOfWeek = (date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day; // Adjust the date to get the start of the week (Sunday)
  d.setDate(diff);
  return formatDateForApi(d); // Return the formatted start date
};

// Function to get all dates of the week
const getDatesOfWeek = (date) => {
  const startOfWeek = new Date(getStartOfWeek(date));
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + i);
    weekDates.push(formatDateForApi(day));
  }

  return weekDates;
};
// Function to get the start and end of the week range (formatted)
const getWeekRange = (date) => {
  const startOfWeekDate = startOfWeek(new Date(date)); // Get the start of the week
  const endOfWeekDate = endOfWeek(new Date(date)); // Get the end of the week

  // Format both dates to "dd" for day and "MMM" for month, without the year for start and end dates
  const startFormatted = format(startOfWeekDate, 'dd'); 
  const endFormatted = format(endOfWeekDate, 'dd');

  // Get the month and year of the end date (assuming both start and end dates are in the same month)
  const monthYearFormatted = format(endOfWeekDate, 'MMM yyyy'); 

  // Return the desired format: "22 - 28 Dec 2024"
  return `${startFormatted} - ${endFormatted} ${monthYearFormatted}`;
};



// Impact display mapping
const impactDisplayMapping = {
  'high': 'High',
  'medium': 'Medium',
  'low': 'Low',
  'no_impact': 'No Impact'
};

export default function PageCalendar() {
  const [isCurrency, setIsCurrency] = useState('Select Currency');
  const [isImpact, setIsImpact] = useState('Select Impact');
  const [newsData, setNewsData] = useState([]);
  const [isPrediction, setIsPrediction] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const impactMapping = {
    'Select Impact': 'Select Impact',
    'High': 'high',
    'Medium': 'medium',
    'Low': 'small',
    'No Impact': 'no_impact'
  };

  const handlePredictionOpen = (newsItem) => {
    setSelectedDate(newsItem.date);
    setSelectedNewsItem(newsItem);
    setIsPrediction(true);
  };

  const handleCurrencyChange = (option) => {
    setIsCurrency(option);
  };

  const handleImpactChange = (option) => {
    const dbValue = impactMapping[option] || '';
    setIsImpact(dbValue);
  };

  const handlePredictionClose = () => {
    setIsPrediction(false);
  };

  // Fetch news data for the selected week
  const { apiCall } = useApi();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const weekDates = getDatesOfWeek(selectedDate); // Get all dates of the week
        const params = {
          day: weekDates, // Pass the entire week
          currency_id: isCurrency !== 'Select Currency' ? isCurrency : undefined,
          impact: isImpact !== 'Select Impact' ? [isImpact] : undefined,
        };

        console.log('Request Params:', params);

        const response = await apiCall(`${API_URL}get-news`, params);

        if (response && response.data && response.data.success === 0 && Array.isArray(response.data.data) && response.data.data.length > 0) {
          setNewsData(response.data.data);
        } else {
          console.warn('No valid news data available for this week');
          setNewsData([]);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching news:', error);
        setNewsData([]);
      }
    };

    fetchNews();
  }, [selectedDate, isCurrency, isImpact]);

  // Memoize today's date so it doesn't trigger re-renders unnecessarily
  const today = useMemo(() => new Date().toISOString().split('T')[0], []);

  // Create a map of news by date and time
  const newsByDateAndTime = useMemo(() => {
    const map = {};
    newsData.forEach(newsItem => {
      newsItem.records.forEach(record => {
        const newsDate = new Date(record.date).toISOString().split('T')[0]; // format date as yyyy-mm-dd
        const time = parseDate(record.date); // Get the time portion (e.g., 4:30)
        console.log('dfgf', time);
        if (!map[newsDate]) {
          map[newsDate] = {};
        }
        if (!map[newsDate][time]) {
          map[newsDate][time] = [];
        }
        map[newsDate][time].push(record); // Group by date and time
      });
    });
    return map;
  }, [newsData]);

  // Calendar navigation functions
  const goToPreviousWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 7);
    setSelectedDate(formatDateForApi(newDate));
  };

  const goToNextWeek = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 7);
    setSelectedDate(formatDateForApi(newDate));
  };
  // Create a list of unique times for the entire week
const uniqueTimes = useMemo(() => {
  const timeSet = new Set();
  newsData.forEach(newsItem => {
    newsItem.records.forEach(record => {
      const time = parseDate(record.date); // Extract time (e.g., "23:00")
      timeSet.add(time); // Add time to the set (only unique times will be kept)
    });
  });
  return Array.from(timeSet).sort(); // Convert the set to an array and sort the times
}, [newsData]);



  return (
    <>
      {!dataLoaded && <Loaders />}
      <Breadcrumb className="" breadcrumbIcon="CalendarSvg" breadcrumbHeading="Calendar" />
      <div className="container-lg cl-custome3">
        <div className="row row-gap-4">
          <div className="col-12">
            <div className="prediction-filter-bx">
              {/* <CalendarNavigation
                date={`${formatDateForDisplay(selectedDate)} (${getWeekRange(selectedDate)})`} 
                prev={goToPreviousWeek}
                next={goToNextWeek}
              /> */}
              <CalendarNavigation
                date={getWeekRange(selectedDate)}  // Only pass the week range to the `date` prop
                prev={goToPreviousWeek}
                next={goToNextWeek}
              />

              <div className="pfb-drop-bx">
                <div className="pfb-drop-item">
                  <div className="sgr-outer-label">Currency</div>
                  <DropItem
                    options={['Select Currency', 'AUD', 'USD', 'CAD', 'EUR', 'CHF', 'CNY', 'GBP', 'JPY', 'NZD']}
                    selectedOption={isCurrency}
                    onChange={handleCurrencyChange}
                  />
                </div>
                <div className="pfb-drop-item">
                  <div className="sgr-outer-label">Impact</div>
                  <DropItem
                    options={['Select Impact', 'High', 'Medium', 'Low', 'No Impact']}
                    selectedOption={impactDisplayMapping[isImpact] || isImpact}
                    onChange={handleImpactChange}
                  />
                </div>
              </div>
            </div>

            <div className="common-box cb-prediction-box p-0">
              {newsData.length > 0 ? (
                <div className="table-responsive tr-prediction">
                  <table className="custom-table off-tr-bg ct-prediction">
                    <thead>
                      <tr>
                        <th className="th-ctp-time">
                          <div className="ctp-time"></div>
                        </th>
                        {/* Render the days of the week dynamically */}
                        {/* {getDatesOfWeek(selectedDate).map((date, index) => (
                          <th key={index}>
                            <div className="ctp-date-item">
                              <div className="ctp-date">{new Date(date).getDate()}</div>
                              {formatDateForDisplay(date).split(' ')[0]}
                            </div>
                          </th>
                        ))} */}
                        {getDatesOfWeek(selectedDate).map((date, index) => {
  const dateDay = new Date(date);  // Get the full date object
  const today = new Date();  // Get today's date
  
  // Format both `date` and `today` to `yyyy-mm-dd` for comparison
  const formattedDate = dateDay.toISOString().split('T')[0];  // Format selected week date
  const formattedToday = today.toISOString().split('T')[0];  // Format today's date (yyyy-mm-dd)

  // Check if the date matches today's date
  const isToday = formattedDate === formattedToday;

  return (
    <th key={index}>
      <div className="ctp-date-item">
        {/* Apply the 'ctp-active' class to today's date */}
        <div className={`ctp-date ${isToday ? 'ctp-active' : ''}`}>
          {dateDay.getDate()}
        </div>
        {formatDateForDisplay(date).split(' ')[0]}
      </div>
    </th>
  );
})}

                      </tr>
                    </thead>
                    <tbody>
  {uniqueTimes.map((time, timeIdx) => {
    return (
      <tr key={time}>
        <td className="td-ctp-time">
          <div className="ctp-time">{time}</div>
        </td>
        {getDatesOfWeek(selectedDate).map((weekDate, dateIdx) => {
          const recordsForDateAndTime = newsByDateAndTime[weekDate]?.[time] || [];
          return (
            <td key={dateIdx}>
              <div className="ctp-item-bx">
                {recordsForDateAndTime.map((record, recordIdx) => (
                  <div key={recordIdx}>
                    <PredictionCard
                      onClick={() => handlePredictionOpen(record)}
                      impact={impactDisplayMapping[record.impact] || 'Unknown'}
                      statusClass={`status-${record.impact === 'no_impact' ? 'blue2' :
                        record.impact === 'high' ? 'red2' :
                        record.impact === 'low' ? 'yellow2' :
                        record.impact === 'medium' ? 'green2' :
                        'default'}`}
                      currency={record.currency_name}
                      event={record.event_type}
                      actual={record.actual || 'N/A'}
                      forecast={record.forecast}
                      previous={record.previous}
                    />
                  </div>
                ))}
              </div>
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>

                  </table>
                </div>
              ) : (
                <EmptyBox className="empty-div-bx" eh="News not found" esh="Try adjusting filters to get results." />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for prediction details */}
      <Modal className="zoom custom-content" centered show={isPrediction} onHide={handlePredictionClose}>
        <div className="custom-modal-header">
          {selectedNewsItem ? (
            <>
              <div className="cmh-lable">
                {formatDateForDisplay(selectedNewsItem.date)}
              </div>
              <div className="cmh-sub-lable">
                Time: {parseDate(selectedNewsItem.date)}
              </div>
            </>
          ) : (
            <div className="cmh-lable">No details available</div>
          )}
          <div className="cmh-close" onClick={handlePredictionClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="custom-modal-body">
          {selectedNewsItem ? (
            <PredictionCard
              className="pci-full mb-4"
              impact={impactDisplayMapping[selectedNewsItem.impact] || 'Unknown'}
              statusClass={`status-${selectedNewsItem.impact === 'no_impact' ? 'obsidian2' :
                  selectedNewsItem.impact === 'high' ? 'red2' :
                    selectedNewsItem.impact === 'small' ? 'yellow2' :
                      selectedNewsItem.impact === 'medium' ? 'green2' :
                        'default'
                }`}
              currency={selectedNewsItem.currency_name}
              event={selectedNewsItem.event_type}
              actual={selectedNewsItem.actual || 'N/A'}
              forecast={selectedNewsItem.forecast}
              previous={selectedNewsItem.previous}
              text={selectedNewsItem.description}
            />
          ) : (
            <div>No news available for this item.</div>
          )}
        </div>
      </Modal>
    </>
  );
}
