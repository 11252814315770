import React from 'react';
import Icons from '../../components/icons';
import ArcGauge from '../ComomonChart/ArcGauge';
import MeterChart from '../ComomonChart/MeterChart';
import ProgressBar from '../ComomonChart/ProgressBar';

export default function LimitCardItem({ className = "col-12", chartType, heading, lcTime, leftAmount, leftText, percent, minTradingDay, maxLoss, maxDailyLoss, balance, threshold, profitTarget }) {

  return (
    <div className={className}>
      <div className="common-box limit-card-bx">
        <div className="lc-heading">{heading}</div>
        {lcTime && <div className="lc-time"><Icons.ClockSvg />{lcTime}</div>}
        <div className="limit-card-inner">

          {chartType === 'line' ? (
            <ProgressBar className="p-0" label={<>{leftAmount} <span>{leftText}</span></>} percent={percent}/>
          ) : chartType === 'halfarc' ? (
            <MeterChart className="dll-meter" label={`${leftAmount} ${leftText}`} percentage={percent} />
          ) : (
            <ArcGauge value={percent} otherValue={`${leftAmount} ${leftText}`} color="#12CA98" color2="#070F0E" />
          )}
        </div>
        <div className="lc-data-bx">
          {minTradingDay && <div className="ldb-item">Minimum trading days <div className='li-data'>{minTradingDay}</div> </div>}
          {maxLoss && <div className="ldb-item">Maximum loss <div className='li-data'>{maxLoss}</div></div>}
          {maxDailyLoss && <div className="ldb-item">Maximum daily loss <div className='li-data'>{maxDailyLoss}</div></div>}
          {balance && <div className="ldb-item">Today's starting balance/equity <div className='li-data'>{balance}</div></div>}
          {threshold && <div className="ldb-item">Threshold at <div className='li-data'>{threshold}</div></div>}
          {profitTarget && <div className="ldb-item">Profit target <div className='li-data'>{profitTarget}</div></div>}
        </div>
      </div>
    </div>
  )
}
