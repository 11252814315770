import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom'
import * as Img from './Img';
import useApi from '../utility/apiCall'; 
import API_URL from '../config/config';

export default function UserInfoBx({ className="" }) {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const isInitialMount = useRef(true);
  const token = state.token || '';
  const [photo, setPhoto] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: '',
    firstName: '',
    lastName: '',
    
  });
  const [errors, setErrors] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const response = await apiCall(API_URL + 'get-profile', {
          user_id: state.userData.id,
          token: token,
          nopaginate: 1,
        });
        if (response.data.success == '0') {
          console.log(response.data);
          setEmail(response.data.data.email);
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
          
          if (response.data.data.thumb_url) {
            setPhotoUrl(response.data.data.thumb_url);
          }
          
        } else {
          // console.log(response.data);
          console.error('Failed to fetch user details:', response.data.error);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
    }
  }, [token]);

  return (
    <Link to="/settings" className={`user-info-bx ${className}`}>
        <div className="uib-img">
          <img src={photoUrl || Img.icon} alt="User Avatar" />
        </div>
        <div className="uib-data">
            <div className="uib-name">{firstName} {lastName}</div>
            <div className="uib-other">{email}</div>
        </div>
    </Link>
  )
}
