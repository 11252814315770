import React from 'react'

export default function BestTodayItem({avatar, rank, name, profit, balance }) {
  return (
    <>
    <div className={"best-today-item"}>
      <div className="bti-avatar">
        <img className="bti-avatar-img" src={avatar} alt="" />
      </div>
      <div className="bti-name">{name}</div>
      <div className='bti-type'>XAUUSD</div>
      <img className='bti-rank-img' src={rank} alt="" />
      <div className="bti-inner">
        <div className='bti-i-item'>
          <div className='bii-lable'>Account size</div>
          <div className='bii-data'>{balance}</div>
        </div>
        <div className='bti-i-item'>
          <div className='bii-lable'>Profit</div>
          <div className='bii-data bd-small'>{profit}</div>
        </div>
      </div>
    </div>
    </>
  )
}
