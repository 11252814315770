import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

import { ThemeMode } from '../../context/ThemeMode';

function TransparentColorGradientBar({ id, isDarkMode }) {
    return (
        <defs>
            <linearGradient id={`${id}`} x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor={isDarkMode ? '#FFFFFF66' : '#4C4A54'} stopOpacity={1} />
                <stop offset="100%" stopColor={isDarkMode ? '#736c77' : '#4C4A54'} stopOpacity={1} />
            </linearGradient>
        </defs>
    );
}

export default function CurrencyVolumeChart({ currencyVolumnData = [] }) {
    const { isDarkMode } = ThemeMode();

    const valueFormatter = (value) => value.toString();

    const transformChartData = () => {
        return currencyVolumnData
            .filter(item => item?.total_lot_size && item?.symbol)
            .map(item => ({
                Volume: item.total_lot_size,
                Currency: item.symbol,
            }));
    };

    const tooltipFormatter = (data) => [
        { name: 'Currency', value: data.Currency },
        { name: 'Volume', value: data.Volume },
    ];

    const maxTotalLotSize = Math.max(0, ...currencyVolumnData.map(item => item.total_lot_size || 0));

    const xAxisTicks = maxTotalLotSize > 0
        ? Array.from({ length: Math.ceil(maxTotalLotSize / 5) + 1 }, (_, i) => i * 5)
        : [0];

    const chartSetting = {
        borderRadius: 4,
        height: 305,
        series: [{
            dataKey: 'Volume',
            color: `${isDarkMode ? "#736c77" : "#736c77"}`,
            area: true,
            showMark: true,
            valueFormatter,
        }],
        margin: { left: 70 },
        yAxis: [{
            scaleType: 'band',
            dataKey: 'Currency',
        }],
        xAxis: [{
            min: 0,
            max: maxTotalLotSize + 10,
            valueFormatter,
            ticks: xAxisTicks,
        }],
        tooltip: {
            formatter: tooltipFormatter,
        },
        sx: {
            [`& .${axisClasses.directionX} .${axisClasses.label}`]: {
                transform: 'translateY(8px)',
            },
            [`& rect`]: {
                fill: `url(#bar-gradient-${isDarkMode ? 'dark' : 'light'})`,
            },
        },
        grid: {
            vertical: { strokeDasharray: '0' },
            horizontal: { strokeDasharray: '0' },
        },
    };

    return (
        <div className="Mycharts volume-chart">
            <BarChart
                dataset={transformChartData()}
                {...chartSetting}
                layout="horizontal"
            >
                <TransparentColorGradientBar id={`bar-gradient-${isDarkMode ? 'dark' : 'light'}`} isDarkMode={isDarkMode} />
            </BarChart>
        </div>
    );
}
