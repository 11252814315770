import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';


//import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// React Bootstrap
import { Modal } from 'react-bootstrap';

// Components File
import WelcomeBox from './WelcomeBox';
import Subscription from './Subscription';
import AccountChart from '../ComomonChart/AccountChart';
import ChallengeCard from '../../components/ChallengeCard';
import CurrencyVolumeChart from '../ComomonChart/CurrencyVolumeChart';
import Loaders from '../../components/Loader';

// Icons & Images Files
import Icons from '../../components/icons';
import master from "../../img/icons/account/master.png"
import student from "../../img/icons/account/student.png"
import practitioner from "../../img/icons/account/practitioner.png"
import starpattern from "../../img/icons/pattern/star-pattern.png"
import conservativeTrader from "../../img/icons/conservative-trader.webp"

import mt5exe from '../../img/mt5.exe'
import Socialicon2 from '../../components/Socialicon2';
import AccountDropItem from '../../components/AccountDropItem';
import MeterChart from '../ComomonChart/MeterChart';
import CloseIcon from '../../components/CloseIcon';
// import LocationMap from '../../components/LocationMap';
import ProgressBar from '../ComomonChart/ProgressBar';
import DropItem from '../../components/commonfield/DropItem';


// 
function TPMSection({ className = "col-12", performancePercentage }) {
  return (
    <>
      <div className={className}>
        <div className="common-box p-0 cb-trade-performance-meter overflowhidden">
          <div className="tpm-inner">
            <div className="common-box-heading">Trade Performance Meter</div>
            <MeterChart percentage={performancePercentage} />
          </div>
          <div className="tpm-info" >
            <div className="tpm-i-item"><div className="tii-squary" style={{ background: "#65BB78" }}></div> Conservative Trader</div>
            <div className="tpm-i-item"><div className="tii-squary" style={{ background: "#FDAA31" }}></div> Medium Risk</div>
            <div className="tpm-i-item"><div className="tii-squary" style={{ background: "#F63D68" }}></div> High Risk</div>
          </div>
        </div>
      </div>
    </>
  )
}

function CTSection({ className = "col-12", performancePercentage, risk }) { 
  // Initialize the progress for each risk category as 0
  let lowRiskPercentage = 0;
  let mediumRiskPercentage = 0;
  let highRiskPercentage = 0;

  // Format the performance percentage to two decimal places
  const formattedPercentage = performancePercentage.toFixed(2);

  // Set the percentage based on the risk type
  if (risk === "low") {
    lowRiskPercentage = formattedPercentage;
  } else if (risk === "medium") {
    mediumRiskPercentage = formattedPercentage;
  } else if (risk === "high") {
    highRiskPercentage = formattedPercentage;
  }

  const isZeroPercentage = formattedPercentage === "0.00";

  return (
    <div className={className}>
      <div className="common-box cb-conservative-trader overflowhidden p-0">
        <img className="ctb-img" src={conservativeTrader} alt="Conservative Trader" />
        <div className="ctb-inner">
          <div className="common-box-heading text-center mt-3">Conservative Trader</div>
          
          {/* Render ProgressBars for low, medium, and high risks */}
          <ProgressBar label="Low-risk products" percent={lowRiskPercentage && !isZeroPercentage ? formattedPercentage : 0} />
          <ProgressBar label="Medium-risk products" percent={mediumRiskPercentage && !isZeroPercentage ? formattedPercentage : 0} />
          <ProgressBar label="High-risk products" percent={highRiskPercentage && !isZeroPercentage ? formattedPercentage : 0} />
        </div>
      </div>
    </div>
  );
}




// 
function CVSection({ className = "col-12", currencyVolumnData }) {
  return (
    <>
      <div className={className}>
        <div className="common-box overflowhidden">
          <div className="common-box-heading">Currency Volume</div>
          <CurrencyVolumeChart currencyVolumnData={currencyVolumnData} />
        </div>
      </div>
    </>
  )
}

// 
function SocialSection({ className = "col-12" }) {
  return (
    <>
      <div className={className}>
        <div className="common-box">
          <Socialicon2 />
        </div>
      </div>
    </>
  )
}

export default function PageDashboard() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // ---
  const [isCredentialsModal, setIsCredentialsModal] = useState(false);
  const [challengeRequests, setChallengeRequests] = useState(false);
  const [realAccountRequests, setRealAccountRequests] = useState(false);
  const [challengeRecords, setChallengeRecords] = useState(false);
  const [challenges, setChallenges] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [accountNumber, setAccountNumber] = useState('');


  const [stepValue, setStepValue] = useState('');
  const [accountStatusValue, setAccountStatusValue] = useState('');
  const [typeValue, setTypeValue] = useState('');

  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [accountDetailNumber, setAccountDetailNumber] = useState('');
  const [accountBalance, setAccountBalance] = useState('');
  const [accountEquity, setAccountEquity] = useState('');
  const [avgWin, setAvgWin] = useState(0);
  const [avgLoss, setAvgLoss] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [accountSize, setAccountSize] = useState(0);
  const [winRatio, setWinRatio] = useState(0);
  const [accountType, setAccountTypeList] = useState([]);
  const [name, setName] = useState('');
  const [currentAccount, setCurrentAccount] = useState([]);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [tradePerformanceData, setTradePerformanceData] = useState(null);
  const [performancePercentage, setPerformancePercentage] = useState(0);
  const [risk, setRisk] = useState(0);
  


  const [filterApply, setFilterApply] = useState(0);

  const [chartType, setChartType] = useState('line');


  const handleCredentialsModalClose = () => {
    setIsCredentialsModal(false);
  };


  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);

    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  const fetchTradePerformanceData = async (accountNumber) => {
    const loggedInUser = getLoggedInUserData();
    if (loggedInUser && loggedInUser.id && token) {
      try {
        setIsLoading(true);
        const response = await apiCall(API_URL + 'get-trade-performance-meter', {
          account_number: accountNumber,
          token: token,
        });
  
        // Log the entire response to understand its structure
        console.log('API Response:', response);
  
        if (response.data.success == 0) {
          const { performancePercentage } = response.data.data;
          console.log('fdgfg', response.data.data.performancePercentage)
          setTradePerformanceData(response.data.data);
          setPerformancePercentage(response.data.data.performancePercentage);
          setRisk(response.data.data.riskType); 
        } else {
          console.error('Failed to fetch trade performance data:', response.data.message || 'No message');
        }
      } catch (error) {
        console.error('Error fetching trade performance data:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error('User not logged in or invalid token');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const userDetail = await getUserProfile();
            //const requests = await getChallengeRequests();
            //const accountRequests = await getRealAccountRequests();
            const records = await getChallengeRecords();
            setDataLoaded(true)
            // console.log(records);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if (activeIndex == 0 && challengeRecords && challengeRecords.length > 0) {
      const option = challengeRecords[activeIndex];
      getProfitDataChart(option.id, selectedOption);
      getUserWalletDetails(option.id);
      getCurrencyVolumn(option.id);
      fetchTradePerformanceData(option.account_number);
      
    }
  }, [challengeRecords]);

  useEffect(() => {
    if (stepValue != '' || accountStatusValue != '' || typeValue != '') {
      getChallengeRequests();
      getRealAccountRequests();
      getChallengeRecords();
    }
  }, [stepValue, accountStatusValue, typeValue])

  const removeSpaceWIthLowerCaseString = (inputString) => {
    return inputString.replace(/\s/g, '').toLowerCase();
  };
  const getUserProfile = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-profile', {
        user_id: state.userData.id,
        token: token,
        nopaginate: 1,
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        setName(response.data.data.first_name + ' ' + response.data.data.last_name);
        if (response.data.data.thumb_url) {
          setPhotoUrl(response.data.data.thumb_url);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getChallengeRequests = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-challenge-requests', {
        user_id: state.userData.id,
        token: token,
        step: stepValue,
        account_status: accountStatusValue,
        type: typeValue,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          setChallengeRequests(response.data.data);
        } else {
          setChallengeRequests(null);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getRealAccountRequests = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-real-account-requests', {
        user_id: state.userData.id,
        token: token,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          console.log('realaccountrequest' + response.data.data);
          setRealAccountRequests(response.data.data);
        } else {
          setRealAccountRequests(null);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleAccountChange = (event) => {
    // console.log(event.target.value);
    const newValue = event.target.value;
    setFilterApply(1);
    if (newValue == 'all') {
      setStepValue('all');
    } else {
      setStepValue(newValue);
    }
  }
  const handleStatusChange = (event) => {
    setFilterApply(1);
    const newValue = event.target.value;
    if (newValue == 'all') {
      setAccountStatusValue('all');
    } else {
      setAccountStatusValue(newValue);
    }

  }
  const handleTypeChange = (event) => {
    setFilterApply(1);
    const newValue = event.target.value;

    if (newValue == 'all') {
      setTypeValue('all');
    } else {
      setTypeValue(newValue);
    }
  }

  const handleCredentialsModalShow = async (challengeID) => {
    try {
      const response = await apiCall(API_URL + 'get-challenge-details', {
        user_id: state.userData.id,
        id: challengeID,
        token: token
      });
      if (response.data.success == '0') {
        // console.log(response.data);
        setEmail(response.data.data.email);
        setAccountNumber(response.data.data.account_number);
        setMasterPassword(response.data.data.master_password);
        setIsCredentialsModal(true);
        // setChallengeDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // alert(challengeID);
    // Logic to get user wallet group-wise data

  };

  const formatStatus = (status) => {
    if (!status) return ''; // Handle case where status might be undefined or null
    if (status == 'passed') {
      status = 'Profit target achieved';
    }
    const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
  };

  const getChallengeRecords = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-challenges', {
        user_id: state.userData.id,
        status: 'active',
        token: token,
        step: stepValue,
        account_status: accountStatusValue,
        type: typeValue,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          //console.log(response.data.data);
          setChallengeRecords(response.data.data);
        } else {
          setChallengeRecords(null);
        }

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAccountTypeList = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-account-type-list', {
        user_id: state.userData.id,
        status: 'active',
        token: token,
        step: stepValue,
        account_status: accountStatusValue,
        type: typeValue,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          console.log('accountType', response.data.data);
          setAccountTypeList(response.data.data);
        } else {
          setAccountTypeList([]);
        }

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  // ---

  const accountListGet = () => {
    const manualEntry = { value: 'all', name: 'All accounts' };
    const mappedData = accountType.map(item => ({
      value: item.step,
      name: item.step
    }))
    return [manualEntry, ...mappedData];

  };

  const Allaccounts = accountListGet();

  const Accountstatus = [
    { value: 'all', name: 'All' },
    // { value: 'New', name: 'New' },
    // { value: 'Ongoining', name: 'Ongoining' },
    { value: 'not_passed', name: 'Not Passed' },
    { value: 'passed', name: 'Passed' },
    // { value: 'CHF', name: 'CHF' },
  ];

  const Accounttype = [
    { value: 'all', name: 'All account' },
    { value: 'Student', name: 'Student' },
    { value: 'Practitioner', name: 'Practitioner' },
    { value: 'Master', name: 'Master' },
  ];

  const handleChange = () => {
  };

  // const getProfitDataChart = async () => {
  //   try {
  //     setIsLoading(true);
  //     // console.log({id});
  //     const response = await apiCall(API_URL +'get-trade-data-for-chart', {
  //         user_id: state.userData.id,
  //         token: token,
  //         user_wallet_id:id
  //     });
  //     if (response.data.success == '0') {
  //       console.log('trading list history', response.data.data.profitData);
  //         setProfitChartData(response.data.data.profitData);
  //         // setProfitChartData(response.data.data.data)
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //       console.error('Error fetching data:', error);
  //   }
  // };


  const [profitChartData, setProfitChartData] = useState([]);
  const [currencyVolumnData, setCurrencyVolumnData] = useState([]);
  useEffect(() => {
    // Function to fetch profit data for a specific user_wallet_id
    // Fetch profit data when activeIndex changes
    if (activeIndex !== null && challengeRecords && challengeRecords.length > 0) {
      const option = challengeRecords[activeIndex];
      setCurrentAccount(option);
      // getProfitDataChart(option.id);
      getProfitDataChart(option.id,selectedOption);
      getUserWalletDetails(option.id);
      getCurrencyVolumn(option.id);
      fetchTradePerformanceData(option.account_number);
    }
  }, [activeIndex]); // Depend on activeIndex changes

  const getProfitDataChart = async (userWalletId, filterOption) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + 'get-trade-data-for-chart', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: userWalletId,
        filter_apply_value: filterOption,
      });
      //console.log('responseresponse',response.data);
      if (response.data.success == '0') {
        // console.log(response.data.data.profitData);
        setProfitChartData(response.data.data.profitData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const getCurrencyVolumn = async (userWalletId) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + 'get-currency-volumn', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: userWalletId,
      });
      //console.log('responseresponse',response.data);
      if (response.data.success == '0') {
        // console.log(response.data.data.profitData);
        setCurrencyVolumnData(response.data.data.trades);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  const getUserWalletDetails = async (userWalletId) => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-trade-dashboard-details', {
        user_id: state.userData.id,
        token: token,
        userWalletID: userWalletId
      });
      //console.log('responseresponse',response.data);
      if (response.data.success == '0') {
        // console.log(response.data.data.profitData);
        const dashboardDetails = response.data.data;
        setAccountDetailNumber(dashboardDetails.challengeInfo.account_number);
        setAccountBalance(dashboardDetails.challengeInfo.available_balance);
        setAccountEquity(dashboardDetails.challengeInfo.equity);
        setAvgWin(dashboardDetails.winAverage);
        setAvgLoss(dashboardDetails.lossAverage);
        setTotalLoss(dashboardDetails.totalLoss);
        setTotalProfit(dashboardDetails.totalProfit);
        setWinRatio(dashboardDetails.winRatio);
        setAccountSize(dashboardDetails.challengeInfo.account_size.limit);
        // setAccountNumber(dashboardDetails.challengeInfo.account_number);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  // Demo
  const [selectedOption, setSelectedOption] = useState('daily');
  const options = ['daily', 'week', 'month'];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log('Selected Option:', option);

    // Call getProfitDataChart with the new option
    getProfitDataChart(challengeRecords[activeIndex].id, option);
  };


  const handleAccontChange = (account) => {
    setActiveIndex(account)
  }

  // Sections hide & show
  const [isDesktop, setIsDesktop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleLogic = () => {
      const width = window.innerWidth;

      if (width >= 992) {
        setIsDesktop(true);
        setIsTablet(false);
        setIsMobile(false);
      } else if (width >= 768) {
        setIsDesktop(false);
        setIsTablet(true);
        setIsMobile(false);
      } else if (width < 768) {
        setIsDesktop(false);
        setIsTablet(false);
        setIsMobile(true);
      }
    };

    // Initial check
    handleLogic();

    // Add event listener
    window.addEventListener("resize", handleLogic);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleLogic);
    };
  }, []);

  return (
    <>
      {!dataLoaded && <Loaders />}

      <div className="container-lg cl-custome3">
        {/* Dashboard Section 02 Start */}
        {(((challengeRecords !== null) || filterApply == 1)) &&
          <>
            <div className="aib-outer">
              <div className="account-info-balance">
                <div className="aib-name">Hello, {name}</div>
                <div className="aib-balance">{accountBalance ? '$' + accountBalance : ''}</div>
              </div>
              <div>
                <div className="cdi-account-label">Account</div>
                <AccountDropItem onChange={handleAccontChange} selected={activeIndex} options={challengeRecords} />
              </div>
            </div>

            <div className="current-challenge-info">
              <div className="cci-right">
                <div className="row row-gap-3">

                  {challengeRecords && challengeRecords.map((option, index) => (
                    // const accountType = option.account_type || 'defaultType'
                    // const step = option.account_type || 'defaultStep'
                    activeIndex == index && <ChallengeCard
                      to={"/account-detail/" + option.id}
                      col="col-sm-6 order-1"
                      className={index == activeIndex ? 'active' : ''}
                      //imgSrc={(option.account_type != undefined && option.account_type.name == 'Student') ? student : (option.account_type != undefined && option.account_type.name == 'Practitioner' ? practitioner : master)}
                      imgSrc={photoUrl || student}
                      step={option.account_type != undefined ? option.account_type.step : ''}
                      name={"MT5 " + option.account_number}
                      //type={option.account_type != undefined ? option.account_type.name : ''}
                      type={((option.account_status == 'real') ? 'Practitioner' : 'Student')}
                      status={formatStatus(option.account_status)}
                      statusClass={(option.account_status == 'not_passed') ? 'status-red2' : (option.account_status == 'refunded' ? 'status-yellow2' : (option.account_status == 'passed' ? 'status-green2' : (option.account_status == 'disabled' ? 'status-yellow2' : 'status-yellow2')))}
                      trades={option.no_of_trades}
                      days={option.days_traded}
                      CredentialsClick={() => handleCredentialsModalShow(option.id)}
                      BoxClick={() => handleCardClick(index)} // Pass index to handleCardClick
                    />
                  ))}

                  <TPMSection className="col-sm-6 order-sm-2 order-3" performancePercentage={performancePercentage} />

                  {(((challengeRequests !== null || challengeRecords !== null))) &&
                    <div className="col-12 order-sm-3 order-2">
                      <div className="dwmy-filter-bx">
                        <div className="common-box-heading mb-0">Account Overview</div>
                        <div className="signinup-group sg-aof-bx sab-dwmy-filter mb-0">
                          {/* <div className="sg-list-bx">
                            <div className="sg-list-item active">Daily</div>
                            <div className="sg-list-item">Weekly</div>
                            <div className="sg-list-item">Monthly</div>
                            <div className="sg-list-item">Yearly</div>
                            
                          </div> */}
                          <div className="sg-list-bx">
                            {options.map((option) => (
                              <div
                                key={option}
                                className={`sg-list-item ${selectedOption === option ? 'active' : ''}`}
                                onClick={() => handleOptionChange(option)}
                              >
                                {option.charAt(0).toUpperCase() + option.slice(1)} {/* Capitalize the first letter */}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="common-box overflowhidden">

                        <div className="signinup-group sg-aof-bx sab-lb-filter">
                          <div className="sg-list-bx">
                            <div className={`sg-list-item ${chartType === 'line' ? 'active' : ''}`} onClick={() => setChartType('line')}>Line Chart</div>
                            <div className={`sg-list-item ${chartType === 'bar' ? 'active' : ''}`} onClick={() => setChartType('bar')}>Bar Chart</div>
                          </div>
                        </div>

                        <div className="aoi-outer">
                          <div className="account-overview-item">
                            <div className="aoi-label">Account balance</div>
                            <div className="aoi-data">${accountBalance > 0 ? parseFloat(accountBalance).toFixed(2) : 0}</div>
                          </div>

                          <div className="account-overview-item">
                            <div className="aoi-label">Equity</div>
                            <div className="aoi-data">${accountEquity > 0 ? parseFloat(accountEquity).toFixed(2) : 0}</div>
                          </div>

                          <div className="account-overview-item">
                            <div className="aoi-label">Profit</div>
                            <div className="aoi-data status-neon-green">{totalProfit != 0 && accountBalance > 0 ? parseFloat((totalProfit / accountSize) * 100).toFixed(2) : 0}%</div>
                          </div>
                        </div>

                        <AccountChart profitChartData={profitChartData} chartType={chartType} filterValue={selectedOption} />

                        <div className="aoi-outer aoi-style-2">
                          <div className="aoi-inner">
                            <div className="account-overview-item">
                              <div className="aoi-label">Average win</div>
                              <div className="aoi-data">${avgWin > 0 ? parseFloat(avgWin).toFixed(2) : 0}<span className='aoi-status-green'>+10.9%</span></div>
                            </div>
                          </div>

                          <div className="aoi-inner">
                            <div className="account-overview-item">
                              <div className="aoi-label">Average loss</div>
                              <div className="aoi-data">${avgLoss > 0 ? parseFloat(avgLoss).toFixed(2) : 0}<span className='aoi-status-red'>-3.9%</span></div>
                            </div>
                          </div>

                          <div className="aoi-inner">
                            <div className="account-overview-item">
                              <div className="aoi-label">Win ratio</div>
                              <div className="aoi-data">{winRatio > 0 ? winRatio.toFixed(2) : 0}%</div>
                            </div>
                          </div>

                          <div className="aoi-inner">
                            <div className="account-overview-item">
                              <div className="aoi-label">Profit Factor</div>
                              <div className="aoi-data">{totalProfit > 0 && totalLoss != 0 ? (totalProfit / totalLoss).toFixed(2) : 0}</div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  }

                  {isDesktop && <Subscription className="order-4"/>}
                </div>
              </div>
              <div className="cci-left">
                <div className="row row-gap-3">
                  <CTSection className="col-lg-12 order-1" performancePercentage={performancePercentage} risk={risk} />
                  <SocialSection className="col-lg-12 order-lg-2 order-last" />
                  <CVSection className="col-lg-12 order-lg-3 order-2" currencyVolumnData={currencyVolumnData} />
                  {/* <LocationMap className="col-lg-12 col-sm-6 order-lg-4 order-3" /> */}
                  {(isTablet || isMobile) && <Subscription className="col-lg-12 order-lg-5 order-4" />}
                </div>
              </div>
            </div>

          </>
        }
        {/* Dashboard Section 02 End */}

        {/* Dashboard Section 01 Start */}
        {(challengeRecords === null && filterApply == 0) &&
          <>
            <div className="pattern-bx">
              <img className="star-pattern" src={starpattern} alt="" />
            </div>
            <WelcomeBox />
          </>
        }
        {/* Dashboard Section 01 End */}
      </div>

      {/* --- Credentials Modal --- */}
      <Modal className="zoom custom-content" centered show={isCredentialsModal} onHide={handleCredentialsModalClose}>
        <div className="custom-modal-header">
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">{'#' + accountNumber}</div>

          <CloseIcon onClick={handleCredentialsModalClose} />
        </div>

        <div className="custom-modal-body">
          <div className="challenge-credentials-bx">

            <div className={"credential-item " + email != '' ? 'd-none' : ''}>
              <div className="credential-label">Email</div>
              <div className="credential-data">{email}<span className="credential-icon" onClick={() => copyClick(email)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Username</div>
              <div className="credential-data">{accountNumber}<span className="credential-icon" onClick={() => copyClick(accountNumber)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Password</div>
              <div className="credential-data">{masterPassword}<span className="credential-icon" onClick={() => copyClick(masterPassword)}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Server</div>
              <div className="credential-data">Winprofx-Live<span className="credential-icon" onClick={() => copyClick("Winprofx-Live")}><Icons.CopySvg /></span></div>
            </div>
            <div className="credential-item">
              <div className="credential-label">Platform</div>
              <div className="credential-data">Metatrader 5<span className="credential-icon" onClick={() => copyClick("Metatrader 5")}><Icons.CopySvg /></span></div>
            </div>
          </div>
          <div className="mt-3 mb-4">
            <a download={true} href={mt5exe} className="common-btn cb-white-fill mx-auto" style={{zIndex:"0"}}><span>Open META TRADER 5</span></a>
          </div>
        </div>
      </Modal>

      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
