import React from 'react'
import { Link } from 'react-router-dom';
import { ThemeMode } from '../context/ThemeMode';

// import logo from "../img/logo/logo.png"
import logoIcon from "../img/logo/icon.png"
import logoText from "../img/logo/text-logo.png"

// import logodark from "../img/logo/logo-dark.png"
import logoIconDark from "../img/logo/icon-dark.png"
import logoTextDark from "../img/logo/text-dark-logo.png"

export default function Logo({ className, to = "/" }) {

  const { isDarkMode } = ThemeMode();

  return (
    <Link to={to} className={`logo-bx ${className}`}>
      {/* <img className="logo-img" src={logo} alt="" /> */}
      {isDarkMode ? (
        <>
          <img className="logo-icon" src={logoIcon} alt="" />
          <img className="logo-text" src={logoText} alt="" />
        </>
      ) : (
        <>
          <img className="logo-icon" src={logoIconDark} alt="" />
          <img className="logo-text" src={logoTextDark} alt="" />
        </>
      )}
    </Link>
  )
}
