import React from 'react'

// Icons & Imges common files
import Icons from '../../components/icons'

export default function BestTypeItem({ heading, avatar, name, amount, data }) {
  return (
    <>
    <div className="best-type-bx">
      <div className="btb-heading">{heading}</div>
      <div className="btb-inner">
        <div className="btb-user-bx">
          <div className="btb-img-bx">
            <img className="btb-user-img" src={avatar} alt="" />
          </div>
          <div>
            <div className="btb-name">{name}</div>
            <div className="btb-amount d-none">{amount}</div>
          </div>
        </div>
        <div className="btb-data"><Icons.RightArrowSvg />{data}</div>
      </div>
    </div>
    </>
  )
}
