import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate,useParams } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useAuth,getLoggedInUserData } from '../../context/AuthContext';

// Context Files
import { useMenu } from '../../context/MenuContext';
import { ThemeMode } from '../../context/ThemeMode';

// 
import * as Img from '../../components/Img';
import Icons from '../../components/icons'
import Logo from "../../components/Logo";
import CloseIcon from "../../components/CloseIcon";
import Switche from '../../components/commonfield/Switche';


export default function Sidemenu() {
    // const { dispatch,state } = useAuth();
    const { apiCall } = useApi();
    // const navigate = useNavigate();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);

    const { id } = useParams();

    // --- ---
    const { isActive, toggleMenu, closeMenu, isMinActive } = useMenu();
    // --- ---
    
    // --- ---
    const { isDarkMode, toggleTheme } = ThemeMode();
    // --- ---
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const handleLogout = () => {
        dispatch({ type: 'LOGOUT'});
        navigate('/');
    }

    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                // getIbRequestStatus();
                // const affiliateCheck = await checkAffiliateAvailable();
                setDataLoaded(true);
                // console.log(tradeHistory);
                // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
              } catch (error) {
                navigate('/login');
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
  
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);
    // const [isAffiliate, setAffiliate] = useState(0);
    //  const checkAffiliateAvailable = async () => {
    //     try {
    //       // setIsLoading(true);
    //       // console.log({id});
    //       const response = await apiCall(API_URL +'check-affiliate-available', {
    //           user_id: state.userData.id,
    //           token: token
    //       });
    //       if (response.data.success == '0') {
    //         //   console.log(response.data.data);
    //           setAffiliate(response.data.data);
    //         //   setTotalPagesDailySummary(response.data.data.last_page)
    //       }
    //       // setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    //   };
  return (
    <>
        {/* Aside Menu Start */}
        <aside className={`side-menu-bx ${isMinActive ? 'smb-mini' : ''}  ${isActive ? 'active' : ''}`} onClick={closeMenu}>

            <div className="smb-header">
                <Logo to="/home" className="smb-logo" />
            </div>

            <div className="smb-menu-bx smb-separator">
                <div>
                    <NavLink to="/new-challenge" className="smb-link highlight-link">
                        <div className="smb-icon"><Icons.challengeSvg /></div><div className="smb-text">New Challenge<div className="smb-icon"><Icons.RightArrowSvg /></div></div>
                    </NavLink>

                    <div className="smb-link-separator"></div>

                    <NavLink to="/home" className="smb-link">
                        <div className="smb-icon"><Icons.HomeSvg /></div><div className="smb-text">Home</div>
                    </NavLink>

                    <NavLink to="/challenge-accounts" className="smb-link">
                        <div className="smb-icon"><Icons.UserCircleSvg /></div><div className="smb-text">Accounts</div>
                    </NavLink>

                    <NavLink to="/payouts" className="smb-link">
                        <div className="smb-icon"><Icons.EwalletsSvg /></div><div className="smb-text">Payouts</div>
                    </NavLink>

                    <NavLink to="/leaderboard" className="smb-link">
                        <div className="smb-icon"><Icons.MedalSvg /></div><div className="smb-text">Leaderboard</div>
                    </NavLink>

                    <NavLink to="/calendar" className="smb-link">
                        <div className="smb-icon"><Icons.CalendarSvg /></div><div className="smb-text">Calendar</div>
                    </NavLink>
                    
                    {/* {isAffiliate == 1 && ( */}
                    <NavLink to="/affiliate" className="smb-link">
                        <div className="smb-icon"><Icons.AffiliateSvg /></div><div className="smb-text">Affiliate</div>
                    </NavLink>
                    {/* )} */}
                </div>

                <div>
                    <NavLink to="/settings" className="smb-link">
                        <div className="smb-icon"><Icons.Cog6Svg /></div><div className="smb-text">Settings</div>
                    </NavLink>

                    <div className="smb-link">
                        <div className="smb-icon"><Icons.ThemeSvg /></div>
                        <div className="smb-text">
                            {isDarkMode ? "Dark mode" : "Light mode"}
                            <Switche
                                onClick={toggleTheme}
                                className={isDarkMode ? "active" : ""}
                                icon={isDarkMode ? <Icons.MoonSvg /> : <Icons.SunSvg />}
                            />
                        </div>
                    </div>

                    <Link className="smb-link" onClick={handleLogout}>
                        <div className="smb-icon"><Icons.LogoutSvg /></div><div className="smb-text">Logout</div>
                    </Link>
                </div>
            </div>
        </aside>
        <div onClick={toggleMenu} className={`side-menu-overlay ${isActive ? 'active' : ''}`}>
            <CloseIcon className="status-white" />
        </div>
    </>
  )
}
