import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons File
import Icons from '../icons';
import profiledefault from '../../img/icons/profiledefault.png';

export default function ProfileUpload({ id, acceptedFileTypes, maxFileSize, onFileUpload, photoUrl }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const maxFileSizeInBytes = maxFileSize * 1024 * 1024; // Convert MB to bytes

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file && file.size > maxFileSizeInBytes) {
            alert(`File size should not exceed ${maxFileSize} MB.`);
            return;
        }
        setSelectedFile(file);
    };

    useEffect(() => {
        if (selectedFile) {
            onFileUpload(selectedFile);
        }
    }, [selectedFile]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add('dragover');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.target.classList.remove('dragover');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.size > maxFileSizeInBytes) {
            alert(`File size should not exceed ${maxFileSize} MB.`);
            return;
        }
        setSelectedFile(file);
        e.target.classList.remove('dragover');
    };

    const deleteFile = () => {
        setSelectedFile(null); // Reset selected file to null, which will restore the photoUrl
    };

    const formatFileSize = (size) => {
        const KB = 1024;
        const MB = KB * KB;
        const GB = MB * KB;

        if (size < KB) {
            return `${size} bytes`;
        } else if (size < MB) {
            return `${(size / KB).toFixed(2)} KB`;
        } else if (size < GB) {
            return `${(size / MB).toFixed(2)} MB`;
        } else {
            return `${(size / GB).toFixed(2)} GB`;
        }
    };

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop();
    };

    return (
        <div className="row row-gap-3">
            <div className="col-12">
                <div className="signinup-group-profile">
                    <label
                        htmlFor={id}
                        className="sgp-lable"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onMouseOut={handleDragLeave}
                    >
                        {/* Display the uploaded file or photoUrl, falling back to default */}
                        <img
                            src={selectedFile ? URL.createObjectURL(selectedFile) : photoUrl || profiledefault}
                            alt="Profile"
                        />
                        <div className="sgp-overlay">
                            <Icons.UploadSvg /> Upload
                        </div>
                    </label>

                    <div className="d-flex column-gap-2">
                        {selectedFile && (
                            <div className="d-none">
                                {selectedFile.name}, {getFileExtension(selectedFile.name)}, {formatFileSize(selectedFile.size)}
                            </div>
                        )}
                        <label htmlFor={id} className="sgp-btn sgp-edit">
                            <Icons.EditSvg /> Change
                        </label>
                        {selectedFile && (
                            <div className="sgp-btn sgp-delete" onClick={deleteFile}>
                                <Icons.TrashSvg /> Delete
                            </div>
                        )}
                    </div>
                </div>
                <input hidden type="file" id={id} accept={acceptedFileTypes} onChange={handleFileSelect} />
            </div>
        </div>
    );
}

ProfileUpload.propTypes = {
    id: PropTypes.string,
    acceptedFileTypes: PropTypes.string,
    maxFileSize: PropTypes.number,
    photoUrl: PropTypes.string,
};

ProfileUpload.defaultProps = {
    id: "fileupload",
    acceptedFileTypes: "image/*",
    maxFileSize: 1,
    photoUrl: "",
};
