import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';

import FlashMessage from '../../components/FlashMessage';
import Loaders from '../../components/Loader';



// import ReactTooltip from 'react-tooltip';

// Icons & Images Files
import Breadcrumb from '../../components/Breadcrumb'
import Icons from '../../components/icons';
import Alert from "../../components/Alert";
// import Pagination from "../../components/Pagination";
import SortIcons from "../../components/SortIcons";


const PageAffiliate = () => {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);


  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState('');

  const [referralLink, setReferralLink] = useState('');
  const [affilateData, setAffilateData] = useState('');

  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);
    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  // const [isAffiliate, setAffiliate] = useState(1);

  // Early redirection based on isAffiliate
  // useEffect(() => {
  //   if (isAffiliate == 0) {
  //     navigate('/home');
  //   }
  // }, [isAffiliate, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            console.log(localStorage.getItem('userData'));
            // if(isAffiliate != '0'){

            // const checkAffiliate = await checkAffiliateAvailable();
            const reffalLink = await getReferralLink();
            const affiliteDetails = await getAffilateDetails();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
            // }
          } catch (error) {
            navigate('/login');
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  // const [isAffiliate, setAffiliate] = useState(1);
  // useEffect(() => {
  //   if(isAffiliate != 1){
  //     navigate('/home');
  //   }
  // },[isAffiliate])

  //  const checkAffiliateAvailable = async () => {
  //     try {
  //       // setIsLoading(true);
  //       // console.log({id});
  //       const response = await apiCall(API_URL +'check-affiliate-available', {
  //           user_id: state.userData.id,
  //           token: token
  //       });
  //       if (response.data.success == '0') {
  //         // console.log(response.data.data);
  //         setAffiliate(response.data.data);
  //         // setTotalPagesDailySummary(response.data.data.last_page)
  //       }
  //       // setIsLoading(false);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //     }
  //   };

  const getReferralLink = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-referral-link', {
        user_id: state.userData.id,
        token: token,
      });
      if (response.data.success == '0') {
        //   console.log('Affilite refreeal ', response.data.data);
        setReferralLink(response.data.data.referral_link);
        //   setTotalPagesTradingHistory(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAffilateDetails = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-affilate-details', {
        user_id: state.userData.id,
        token: token,
      });
      if (response.data.success == '0') {
        console.log('Affilite refreeal ', response.data.data);
        setAffilateData(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleToNavigate = () => {
    navigate('/payouts')
  }
  return (
    <>
      {!dataLoaded && <Loaders />}

      <Breadcrumb className="" breadcrumbIcon="AffiliateSvg" breadcrumbHeading="Affiliate" />
      <div className='container-lg cl-custome3'>
        <div className='row row-gap-4'>

          <div className="col-12">
            <Alert
              className="alert-style3 alert-wbtn alert-fd-column common-box referral-bx referral-btn"
              icon="UserPlushSvg"
              heading="Your Referral Code"
              text="Use this URL to promote across different platforms and track your conversions on the referrals tab, this link is unique to you."
              btnIcon="CopySvg"
              btnText={referralLink}
              btnClick={() => copyClick(referralLink)}
            />
          </div>

          <div className="col-12">
            <Alert
              className="alert-wbtn affiliate-program-bx"
              icon="ExclamationCircleSvg"
              heading="Click on the link to know more about how affiliates work"
              linkText="Visit Here"
              linkTo="##"
              linkTarget="_blank"
            />
          </div>

          <div className="col-12">
            <div className='common-box cb-all-none'>
              <div className='row row-gap-3 mb-3'>
                <div className='col-md-4'>
                  <div className="affiliate-data-item common-box">
                    <div className="adi-icon"><Icons.ShoppingBagSvg /></div>
                    <div className="adi-heading">Total Purchased</div>
                    <div className="adi-data">{affilateData.countOfUser}</div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="affiliate-data-item common-box">
                    <div className="adi-icon"><Icons.CurrencyDollarSvg /></div>
                    <div className="adi-heading">Total Earned</div>
                    <div className="adi-data">${affilateData.totalEarned > 0 ? affilateData.totalEarned.toFixed(2) : 0}</div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="affiliate-data-item common-box">
                    <div className="adi-icon"><Icons.WithdrawSvg /></div>
                    <div className="adi-heading">Total Paid Out</div>
                    <div className="adi-data">${affilateData.totalPaidOut > 0 ? affilateData.totalPaidOut.toFixed(2) : 0}</div>
                  </div>
                </div>
              </div>
              <div className='input-note text-center'>Please use the <span onClick={handleToNavigate}>payouts</span> section to request a payout</div>
            </div>
          </div>

          {(affilateData.referralCodeUserFind && affilateData.referralCodeUserFind.length > 0 && !isLoading) &&
          <div className="col-12">
            <div className='common-box'>

                <div className="table-responsive custom-table-responsive">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        {/* <SortIcons /> */}
                        <th>REFERRAL COMPLETED AT</th>
                        <th>ACCOUNT SIZE</th>
                        <th>ACCOUNT TYPE</th>
                        <th>INCENTIVE/FEE</th>

                      </tr>
                    </thead>
                    <tbody>
                      {affilateData.referralCodeUserFind.map((referralUser, index) =>
                        referralUser.wallets
                          .filter(wallet => wallet.account_type.step !== 'HFT') // Filter out wallets with step 'HFT'
                          .map((wallet, walletIndex) => {
                            // Determine the percentage based on account_type.step
                            const percentage = wallet.account_type.step === '1 step' ? 10 :
                              wallet.account_type.step === '2 step' ? 15 :
                                0;

                            return (
                              <tr key={`${index}-${walletIndex}`}>
                                <td>{wallet.created_date_time_formattad || ''}</td>
                                <td>{wallet.account_size.limit || 0}</td>
                                <td><span class="td-status ts-step status-green4 ">{wallet.account_type.step || ''}</span></td>
                                <td>${(parseFloat(wallet.account_size.price) * (percentage / 100)).toFixed(2)}</td>
                              </tr>
                            );
                          })
                      )}

                    </tbody>
                  </table>
                </div>
              {/* <Pagination 
                  currentPage={51}
                  totalPages="101"
                  /> */}

            </div>

          </div>
          }
          
        </div>
      </div>

      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}

export default PageAffiliate