import React, { useState, useRef, useEffect } from 'react'

// Icons & Imges common files
import * as Img from '../../components/Img';

// 
import Breadcrumb from '../../components/Breadcrumb'
import BestTypeItem from './BestTypeItem'
import BestTodayItem from './BestTodayItem'
import BestAccounts from "./BestAccounts"
import Loaders from '../../components/Loader';


import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';

export default function PageLeaderboard() {

  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();
  //Loader
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);


  const [accountSizeList, setAccountSizeList] = useState([]);
  const [accountProfitList, setAccountProfitList] = useState([]);
  const [winnerList, setWinnerList] = useState([]);
  const [firstBlockList, setFirstBlockList] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // getIbRequestStatus();
            const profitChart = await getLeaderBoardData();
            const firstBlock = await getLeaderBoardFirstBlockList();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        } else {
          navigate('/');
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const getLeaderBoardData = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-winner', {
        user_id: state.userData.id,
        token: token,
      });
      if (response.data.success == '0') {
        console.log('winnerresponse.data.data', response.data.data);

        setWinnerList(response.data.data);
        // console.log('trading list history',response.data.data.last_page);
        // setTradingHistoryList(response.data.data.data)
        // setTotalPagesTradingHistory(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getLeaderBoardFirstBlockList = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-leader-board-first-block-data', {
        user_id: state.userData.id,
        token: token
      });
      if (response.data.success == '0') {
        console.log('response.data.data', response.data.data);
        setFirstBlockList(response.data.data)
        // console.log('trading list history',response.data.data.last_page);
        // setTradingHistoryList(response.data.data.data)
        // setTotalPagesTradingHistory(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const getHoursDifference = (targetDate) => {
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursDifference;
  };

  let TodayBestItem = [];
  if (winnerList.length > 0) {
    if (winnerList.length > 2) {
      TodayBestItem = [
        { name: winnerList[0].first_name + ' ' + winnerList[0].last_name, avatar: winnerList[0].thumb_url, rank: Img.Medal1, profit: "$" + winnerList[0].profit, balance: "$" + winnerList[0].account_size },
        { name: winnerList[1].first_name + ' ' + winnerList[1].last_name, avatar: winnerList[1].thumb_url, rank: Img.Medal2, profit: "$" + winnerList[1].profit, balance: "$" + winnerList[1].account_size },
        { name: winnerList[2].first_name + ' ' + winnerList[2].last_name, avatar: winnerList[2].thumb_url, rank: Img.Medal3, profit: "$" + winnerList[2].profit, balance: "$" + winnerList[2].account_size }
      ];
    } else if (winnerList.length == 2) {
      TodayBestItem = [
        { name: winnerList[0].first_name + ' ' + winnerList[0].last_name, avatar: winnerList[0].thumb_url, rank: Img.Medal1, profit: "$" + winnerList[0].profit, balance: "$" + winnerList[0].account_size },
        { name: winnerList[1].first_name + ' ' + winnerList[1].last_name, avatar: winnerList[1].thumb_url, rank: Img.Medal2, profit: "$" + winnerList[1].profit, balance: "$" + winnerList[1].account_size },
      ];
    } else {
      TodayBestItem = [
        { name: winnerList[0].first_name + ' ' + winnerList[0].last_name, avatar: winnerList[0].thumb_url, rank: Img.Medal1, profit: "$" + winnerList[0].profit, balance: "$" + winnerList[0].account_size },
      ];
    }
  }

  return (
    <>
      {!dataLoaded && <Loaders />}
      <Breadcrumb className="" breadcrumbIcon="MedalSvg" breadcrumbHeading="Leaderboard" />
      <div className="container-lg cl-custome3">
        <div className="row row-gap-4">

          <div className="col-12">
            <div className="row row-gap-3">
              {(firstBlockList && firstBlockList.highest_payout) &&
              <div className="col-sm-6">
                <BestTypeItem
                  heading="Highest Payout"
                  avatar={Img.Avatar7}
                  name={firstBlockList.highest_payout.user_name}
                  amount={"$" + firstBlockList.highest_payout.account_size}
                  data={firstBlockList.highest_payout.newpayout + "%"}
                />
              </div>
              }

              {(firstBlockList && firstBlockList.highest_win_ratio.win_ratio > 0) &&
              <div className="col-sm-6">
                <BestTypeItem
                  heading="Best Win Ratio"
                  avatar={firstBlockList.highest_win_ratio.thumb_url || Img.Avatar3}
                  name={firstBlockList.highest_win_ratio.user_name}
                  amount={"$" + firstBlockList.highest_win_ratio.account_size}
                  data={<>{firstBlockList.highest_win_ratio.win_ratio > 0 ? parseFloat(firstBlockList.highest_win_ratio.win_ratio).toFixed(2) : 0}%</>}
                />
              </div>
              }
              {(firstBlockList && firstBlockList.other_fastest_evalution_data != 0 && firstBlockList.other_fastest_evalution_data.account_size != null) &&
              <div className="col-md-4 d-none">
                <BestTypeItem
                  heading="Fastest Evaluation"
                  avatar={Img.Avatar1}
                  name={firstBlockList.other_fastest_evalution_data.user.first_name + " " + firstBlockList.other_fastest_evalution_data.user.last_name}
                  amount={"$" + firstBlockList.other_fastest_evalution_data.account_size.limit}
                  data={<>{firstBlockList.fastest_evelution}</>}
                />
              </div>
              }
            </div>
          </div>

          <div className="col-12">
            <div className="best-today-row">
              {TodayBestItem.map((item, index) => (
                <BestTodayItem key={index} {...item} />
              ))}
            </div>
          </div>

          <BestAccounts />
        </div>
      </div>
    </>
  )
}
