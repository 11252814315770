import React from 'react'

function Ascending() {
  return (    
  <svg viewBox="0 0 16 16" fill="none" stroke="currentColor" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12.6666V3.33325"/>
    <path d="M3.3335 7.99992L8.00016 3.33325L12.6668 7.99992"/>
  </svg>
  )
}

// function Descending() {
//   return (    
//   <svg viewBox="0 0 16 16" fill="none" stroke="currentColor" stroke-width={2} strokeLinecap="round" strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
//     <path d="M8 3.33342L8 12.6667"/>
//     <path d="M12.6665 8.00008L7.99984 12.6667L3.33317 8.00008"/>
//   </svg>
//   )
// }

export default function SortIcons() {
  return (
    <>
        <div className="th-sort-icons">
          <Ascending />
          {/* <Descending /> */}
        </div>
    </>
  )
}
