import React, { useState, useRef, useEffect } from 'react'


import Icons from '../../components/icons';
import * as Img from '../../components/Img';

import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';
import Button from 'react-bootstrap/Button';
import EmptyBox from '../../components/EmptyBox';
import Loaders from '../../components/Loader';
import CloseIcon from '../../components/CloseIcon';


import Breadcrumb from '../../components/Breadcrumb'

// Common Field
import Input from '../../components/commonfield/Input'
import SelectInput from '../../components/commonfield/SelectInput'
import PaymentMethodItem from '../PageChallenge/PaymentMethodItem'
import Alert from '../../components/Alert';




export default function PagePayoutsRequest() {
  //Auth related var

  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);

  const { id } = useParams();

  //Loader
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [userWalletAccountList, setUserWalletAccountList] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');


  const [inPaymentMethod, setInPaymentMethod] = useState(0);
  const [amount, setAmount] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [bankName, setBankName] = useState('');
  const [addressID, setAddressID] = useState('');
  const [selectedUserWalletID, setSelectedUserWalletID] = useState('');
  const [affilateBalance, setAffilateBalance] = useState(0);
  const [accountType, setAccountTypeList] = useState([]);
  const [stepFilterValue, setStepFilterValue] = useState('all');


  const [tab, setTab] = useState('step1');
  const handleStepClick = (stepId) => {
    if (stepId == 'step2') {
      setRequestSubmitError(false);
      if (selectedUserWalletID == '' && activeTab == 'trading_account') {
        setRequestSubmitError(true);
        setRequestSubmitErrorMsg('Please select user wallet account');

      } else {
        setRequestSubmitError(false);
        setTab(stepId)
        setInPaymentMethod(0);
      }
    } else {
      setTab("step1")
    }
    setPaymentMethodName('');
    setPaymentMethodId('');
    // setRequestSubmitError(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;

      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // getIbRequestStatus();
            // const checkAffiliate = await checkAffiliateAvailable();
            const paymentMethod = await getPaymentMethods();
            const accountType = await getAccountTypeList();
            setDataLoaded(true);
            // console.log(tradeHistory);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch ib data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  // const accountListGet = () => {
  //   const manualEntry = { value: 'all', name: 'All accounts' };
  //   const mappedData = accountType.map(item => ({
  //                         value: item.step,
  //                         name: item.step
  //                       }))
  //   return [manualEntry, ...mappedData];

  // };
  useEffect(() => {
    if (stepFilterValue != 'all') {
      getRealUserWalletAccount();
    }
  }, [stepFilterValue]);

  const getAccountTypeList = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-account-type-list', {
        user_id: state.userData.id,
        status: 'active',
        token: token,
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          console.log('accountType', response.data.data);
          setAccountTypeList(response.data.data);
        } else {
          setAccountTypeList([]);
        }

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getRealUserWalletAccount = async () => {
    try {
      //setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-real-user-wallet', {
        user_id: state.userData.id,
        stepFilterValue: stepFilterValue,
        token: token
      });
      if (response.data.success == '0') {
        console.log(response.data.data);
        setUserWalletAccountList(response.data.data);
        //   setDailySummaryTradingList(response.data.data.data)
        //   setTotalPagesDailySummary(response.data.data.last_page)
      }
      //setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const getAffilateBalance = async () => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL + 'get-affilate-balance', {
        user_id: state.userData.id,
        token: token
      });
      if (response.data.success == '0') {
        console.log('affilite', response.data.data);
        setAffilateBalance(response.data.data.amount);
        //   setDailySummaryTradingList(response.data.data.data)
        //   setTotalPagesDailySummary(response.data.data.last_page)
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPaymentMethods = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL + 'get-payment-methods', {
        user_id: state.userData.id,
        token: token,
        nopaginate: 1
      });
      if (response.data.success == '0') {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const transformChartData = () => {
    // console.log('profitChartDataprofitChartDataprofitChartDataprofitChartData', profitChartData);
    return userWalletAccountList.map(item => ({
      value: item.id,
      name: "MT5 " + item.account_number
    }));
  };

  const Accounttype = transformChartData();

  const handleChange = () => {
  };

  const removeSpacesAndLowercase = (inputString) => {
    // Remove spaces using regex and convert to lowercase
    const processedString = inputString.replace(/\s+/g, '').toLowerCase();
    return processedString;
  };



  const handlePaymentMethodIdChange = (payment_method, method_name) => {
    setPaymentMethodId(payment_method);
    setInPaymentMethod(1)
    setPaymentMethodName(removeSpacesAndLowercase(method_name));
    // alert(removeSpacesAndLowercase(method_name))
  }

  const demo = () => {
  }

  // Effect to set the first item as active by default when accountType changes
  useEffect(() => {
    if (accountType && accountType.length > 0) {
      setStepFilterValue(accountType[0].id);
    }
  }, [accountType]);

  const onChageAmount = (value) => {
    setAmount(value);
    setErrors((prev) => ({ ...prev, amount: '' }));
  }
  const onChageAccountName = (value) => {
    setAccountName(value);
    setErrors((prev) => ({ ...prev, accountName: '' }));
  }
  const onChageAccountNumber = (value) => {
    setAccountNumber(value);
    setErrors((prev) => ({ ...prev, accountNumber: '' }));
  }
  const onChageIFSCCode = (value) => {
    setIfscCode(value);
    setErrors((prev) => ({ ...prev, ifscCode: '' }));
  }
  const onChageBankName = (value) => {
    setBankName(value);
    setErrors((prev) => ({ ...prev, bankName: '' }));
  }
  const onChageAddressID = (value) => {
    setAddressID(value);
    setErrors((prev) => ({ ...prev, addressID: '' }));
  }

  const validateAccountFields = () => {
    const newErrors = {};

    if (!amount && activeTab == 'affiliate') {
      newErrors.amount = 'Amount is required';
    }

    if (amount > affilateBalance && activeTab == 'affiliate') {
      newErrors.amount = 'Affliate balance is lower than you enter amount please check';
    }

    if (!accountName && paymentMethodName == "upi") {
      newErrors.accountName = 'Account Name is required';

    }

    if (!accountNumber && paymentMethodName == "upi") {
      newErrors.accountNumber = 'Account number is required';

    }

    if (!ifscCode && paymentMethodName == "upi") {
      newErrors.ifscCode = 'IFSC code is required';

    }

    if (!bankName && paymentMethodName == "upi") {
      newErrors.bankName = 'Bank name is required';

    }

    if (!addressID && paymentMethodName != "upi") {
      newErrors.addressID = 'Address ID is required';

    }

    // if (!selectedUserWalletID) {
    //   newErrors.setSelectedUserWalletID = 'Please select first your wallet account';

    // }

    return newErrors;
  };

  const handleUserWalletChange = (event) => {
    setSelectedUserWalletID(event.target.value);
    setErrors((prev) => ({ ...prev, selectedUserWalletID: '' }));
  }

  const handleAccountSubmit = async () => {
    setIsLoading(true);
    setRequestSubmitError(false);
    setRequestSubmitSuccsess(false);
    const valErrors = validateAccountFields();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      //   console.log(phoneNumber);

      formData.append('user_id', state.userData.id);
      formData.append('token', token);
      formData.append('amount', amount);
      formData.append('payment_method_id', paymentMethodId);
      formData.append('user_wallet_id', selectedUserWalletID);
      formData.append('payout_type', activeTab);
      if (paymentMethodName == "upi") {
        formData.append('account_holder_name', accountName);
        formData.append('account_number', accountNumber);
        formData.append('account_ifsc_code', ifscCode);
        formData.append('account_bank_name', bankName);
      }
      if (paymentMethodName != "upi") {
        formData.append('payment_wallet_address_id', addressID);
      }

      try {



        // Proceed with the API call if no balance error

        // Send FormData using Axios
        // if (amount > affilateBalance && activeTab == 'affiliate') {
        //     setIsLoading(false);
        //     setRequestSubmitError(true);
        //     setRequestSubmitErrorMsg('Affliate balance is lower than you enter amount please check');
        // } else {
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'create-request-of-payouts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.success == 0) {
              console.log(response.data);
              setIsLoading(false);
              setRequestSubmitSuccsess(true);
              setRequestSubmitSuccessMsg('Payout request added successfully');
              setTimeout(() => {
                navigate('/payouts');
              }, 2000); // Replace '/success-page' with your desired route
            } else {
              setIsLoading(false);
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
          })
          .catch(error => {
            setIsLoading(false);
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg(error.response.data.message)
            //console.error('Error uploading profile picture:', error);
          });
        // }
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
      setRequestSubmitError(false); // Reset error state if there are validation errors
      setIsLoading(false); // Ensure loading is turned off if validation fails
    }
  };

  // const [isAffiliate, setAffiliate] = useState(1);
  // useEffect(() => {
  //   if(isAffiliate != 1){
  //     navigate('/home');
  //   }
  // },[isAffiliate])

  //  const checkAffiliateAvailable = async () => {
  //     try {
  //       // setIsLoading(true);
  //       // console.log({id});
  //       const response = await apiCall(API_URL +'check-affiliate-available', {
  //           user_id: state.userData.id,
  //           token: token
  //       });
  //       if (response.data.success == '0') {
  //         // console.log(response.data.data);
  //         setAffiliate(response.data.data);
  //         // setTotalPagesDailySummary(response.data.data.last_page)
  //       }
  //       // setIsLoading(false);
  //     } catch (error) {
  //         console.error('Error fetching data:', error);
  //     }
  //   };

  const [activeTab, setActiveTab] = useState('trading_account');

  const handleTabClick = (tab) => {

    if (tab == 'affiliate') {
      getAffilateBalance();
    }
    setActiveTab(tab);
  };

  const handleNavigateToPayout = () => {
    navigate('/payouts');
  }

  const handleFilterChange = (stepValue) => {
    setStepFilterValue(stepValue);
  }




  return (
    <>
      {!dataLoaded && <Loaders />}

      <Breadcrumb className="" breadcrumbIcon="WithdrawSvg" breadcrumbHeading="Request Payout" middleLink="/payouts" middleHeading="Payouts" />
      <div className="container-lg cl-custome3">
        <div className="common-box payouts-form-box">
          
            {/* --- Challenge Step 1 start  --- */}
            <div className={`common-box cb-all-none ${tab == "step1" ? '' : 'd-none'}`} id="step1">
              <CloseIcon className="ci-tr-0px" onClick={() => handleNavigateToPayout()} />
              <div className='common-box-heading'>Request A New Payout</div>
              <div className='common-section-separator'></div>
              <div className='common-box-text'>Use this form to request a new payout, we will verify and get in touch with you for further advancements</div>

              {/* trading account */}
              <div className="signinup-group mt-3">
                <div className="sg-list-bx">
                  <div className={`sg-list-item ${activeTab === 'trading_account' ? 'active' : ''}`} onClick={() => handleTabClick('trading_account')}>Trading Account</div>
                  <div className={`sg-list-item ${activeTab === 'affiliate' ? 'active' : ''}`} onClick={() => handleTabClick('affiliate')}>Affiliate</div>
                </div>
              </div>

              {activeTab === 'trading_account' && (
                <>
                  <div className="signinup-group">
                    <div className="sg-list-bx">
                      {(accountType && accountType.length > 0 && !isLoading) &&
                        accountType.map((stepAccountType, index) => (
                          <div className={"sg-list-item " + (stepFilterValue == stepAccountType.id ? "active" : '')} onClick={() => handleFilterChange(stepAccountType.id)}>{stepAccountType.step}</div>
                        ))
                      }
                    </div>
                  </div>

                  <div className="legal-content p-0">
                    <div className="legal-heading">Note:</div>
                    <ul className="ul-dick">
                      <li>Payouts can be requested on monthly basis.</li>
                      <li>ALL TRADES MUST BE CLOSED INCLUDING PENDING ORDERS.</li>
                      <li>Please wait for at least 15 mins after you close your latest trade to request a payout.</li>
                      <li>Minimum amount for an eligible payout is 1% of initial balance.</li>
                      <li>Payouts below $500 will be processed via crypto. Payouts above $500 will be processed via Bank transfer.</li>
                      <li>If Bank transfer does not support the country, the payout will be processed through crypto.</li>
                    </ul>
                  </div>

                  <div className='common-section-separator'></div>

                  <div className='col-12'>
                    <SelectInput heading="Choose Account" className="sgl-opacity1" defaultOption={'Select Account'} options={Accounttype} sglIcon='' value={selectedUserWalletID} onChange={handleUserWalletChange} errorMeg={errors?.selectedUserWalletID} error={errors?.selectedUserWalletID} />
                  </div>
                </>
              )}

              {activeTab === 'affiliate' && (
                <>
                {(affilateBalance < 100 && affilateBalance > 0) &&
                  <Alert
                    className="alert-style4 status-red2"
                    icon="CurrencyDollar2Svg"
                    heading="Minimum amount of 100$ for affiliate withdrawal."
                  />
                } 

                {affilateBalance <= 0 &&
                  <Alert 
                    className="alert-style4 status-red2"
                    icon="CurrencyDollar2Svg"
                    heading="You are not eligible for an affiliate payout yet"
                  />
                } 
              
                {/* {affilateBalance >= 100 &&  */}
                  <div className="affiliate-balance">
                    <div className="ab-name">Amount</div>
                    <div className="ab-balance">${affilateBalance}</div>
                  </div>
                {/* } */}
                </>
              )}

              {((affilateBalance >= 100 && activeTab == "affiliate") || (activeTab == "trading_account")) &&
                <div onClick={() => handleStepClick('step2')} className="common-submit-btn csb-width-full mb-0">Continue</div>
              }
            </div>
            {/* --- Challenge Step 1 end  --- */}


            {/* --- Challenge Step 2 start  --- */}
            <div className={`common-box cb-all-none ${inPaymentMethod == 0 && tab == "step2" ? '' : 'd-none'}`} id="step2">
              <div onClick={() => handleStepClick('step1')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
              <div className='text-center cbh-16small mb-0"'>Choose payment method</div>
              <div className='common-section-separator'></div>
              <div className='row row-gap-3'>
                {paymentMethods && paymentMethods.map((option, index) => (
                  <PaymentMethodItem className="col-lg-4 col-sm-6 col-12" src={option.image_url} alt={option.name} heading={option.name} key={index} onClick={() => handlePaymentMethodIdChange(option.id, option.name)} />
                ))}
              </div>
            </div>
            {/* --- Challenge Step 2 end  --- */}


            {/* --- Challenge Step 3 start  --- */}
            <div className={`common-box cb-all-none ${inPaymentMethod != 0 ? '' : 'd-none'}`} id="step3">
              <div onClick={() => handleStepClick('step2')} className="common-back-btn cbb-absolute p-0"><Icons.ArrowBackSvg />Back</div>
              <div className='common-box-heading cbh-16small text-center mb-0'>Fill payout request</div>
              <div className='common-section-separator'></div>
            
              {(paymentMethodName != " " && paymentMethodName == "upi") &&
                <div className='row'>
                  {activeTab == "affiliate" &&
                    <div className="col-md-6 ">
                      <Input heading="Amount" type="number" placeholder="Enter amount" onChange={onChageAmount} value={amount} errorMeg={errors?.amount} error={errors?.amount} />
                    </div>
                  }

                  <div className="col-md-6">
                    <Input heading="Account Holder Name" type="text" placeholder="Enter Account Name" onChange={onChageAccountName} value={accountName} errorMeg={errors?.accountName} error={errors?.accountName} />
                  </div>

                  <div className="col-md-6">
                    <Input heading="Account Number" type="text" placeholder="Enter Account Number" onChange={onChageAccountNumber} value={accountNumber} errorMeg={errors?.accountNumber} error={errors?.accountNumber} />
                  </div>

                  <div className="col-md-6">
                    <Input heading="IFSC Code" type="text" placeholder="Enter IFSC Code" onChange={onChageIFSCCode} value={ifscCode} errorMeg={errors?.ifscCode} error={errors?.ifscCode} />
                  </div>

                  <div className="col-md-6">
                    <Input heading="Bank Name" type="text" placeholder="Enter Bank Name" onChange={onChageBankName} value={bankName} errorMeg={errors?.bankName} error={errors?.bankName} />
                  </div>
                </div>
              }

              {(paymentMethodName != " " && paymentMethodName != "upi") &&
                <div className='row'>
                  {activeTab == "affiliate" &&
                    <div className="col-md-6 ">
                      <Input heading="Amount" type="number" placeholder="Enter amount" onChange={onChageAmount} value={amount} errorMeg={errors?.amount} error={errors?.amount} />
                    </div>
                  }
                  <div className="col-md-6 ">
                    <Input heading="Withdraw Address" type="text" placeholder="Enter address id" onChange={onChageAddressID} value={addressID} errorMeg={errors?.addressID} error={errors?.addressID} />
                  </div>
                </div>
              }

              <div className="cfb-btn mt-2">
                {!isLoading && <div className="common-submit-btn csb-width-full mb-0" onClick={handleAccountSubmit}>Submit</div>}
                {isLoading && <div className="lsb-loader-btn csb-width-full mb-0"><img src={Img.loading} alt="" />Please wait ....</div>}
              </div>
            </div>
            {/* --- Challenge Step 3 end  --- */}
        </div>
      </div>

      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
