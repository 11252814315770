import React from 'react'
import ProgressBar from '../ComomonChart/ProgressBar'

export default function ScaleOverviewItem({heading, src, icon, min, max, percent, current }) {
  return (
  <div className="col-sm-6">
    <div className="common-box">
      <div className="scale-overview-item">
        {src && <div className="common-box-heading"><img src={src} alt="" /> {heading}</div>}
        {icon &&<div className="common-box-heading">{icon} {heading}</div>}
        <ProgressBar className="p-0" barStyle="barStyle2" minvalue={<>{min} <span>min</span> </>} maxvalue={<>{max} <span>max</span> </>} current={current} percent={percent} />
      </div>
    </div>
  </div>
  )
}
