import React from 'react';
import Icons from './icons';

export default function Pagination({ currentPage, totalPages, onPageChange, prev, next }) {
  const renderPageNumbers = () => {
    const pages = [];

    if (totalPages <= 7) {
      // Show all pages if total pages <= 7
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <div
            key={i}
            className={`pnb-item ${currentPage === i ? 'pnb-current' : ''}`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </div>
        );
      }
    } else {
      // Always show the first page
      pages.push(
        <div
          key={1}
          className={`pnb-item ${currentPage === 1 ? 'pnb-current' : ''}`}
          onClick={() => onPageChange(1)}
        >
          1
        </div>
      );

      if (currentPage <= 4) {
        // Show pages 2, 3, 4, 5 if current page is near the start
        for (let i = 2; i <= 5; i++) {
          pages.push(
            <div
              key={i}
              className={`pnb-item ${currentPage === i ? 'pnb-current' : ''}`}
              onClick={() => onPageChange(i)}
            >
              {i}
            </div>
          );
        }
        pages.push(<div key="end-ellipsis" className="pnb-item pnb-disabled"><Icons.EllipsisHorizontalSvg /></div>);
      } else if (currentPage >= totalPages - 3) {
        // Show pages near the end if current page is near the end
        pages.push(<div key="start-ellipsis" className="pnb-item pnb-disabled"><Icons.EllipsisHorizontalSvg /></div>);
        for (let i = totalPages - 4; i < totalPages; i++) {
          pages.push(
            <div
              key={i}
              className={`pnb-item ${currentPage === i ? 'pnb-current' : ''}`}
              onClick={() => onPageChange(i)}
            >
              {i}
            </div>
          );
        }
      } else {
        // Current page is in the middle
        pages.push(<div key="start-ellipsis" className="pnb-item pnb-disabled"><Icons.EllipsisHorizontalSvg /></div>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(
            <div
              key={i}
              className={`pnb-item ${currentPage === i ? 'pnb-current' : ''}`}
              onClick={() => onPageChange(i)}
            >
              {i}
            </div>
          );
        }
        pages.push(<div key="end-ellipsis" className="pnb-item pnb-disabled"><Icons.EllipsisHorizontalSvg /></div>);
      }

      // Always show the last page
      pages.push(
        <div
          key={totalPages}
          className={`pnb-item ${currentPage === totalPages ? 'pnb-current' : ''}`}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </div>
      );
    }

    return pages;
  };

  return (
    <div className="pagination-filter-bx">
      <div className="pagination-bx">
        <div className={`pb-prev ${currentPage === 1 ? 'pb-disabled' : ''}`} onClick={currentPage > 1 ? prev : undefined}>
          <Icons.LeftArrowSvg />
        </div>

        <div className="pb-number-bx">{renderPageNumbers()}</div>

        <div className={`pb-next ${currentPage === totalPages ? 'pb-disabled' : ''}`} onClick={currentPage < totalPages ? next : undefined}>
          <Icons.RightArrowSvg />
        </div>
      </div>
    </div>
  );
}
