import React, { useState, useEffect } from 'react';
import { ThemeMode } from '../context/ThemeMode';

import logoIcon from "../img/logo/icon.png"
import logoIconDark from "../img/logo/icon-dark.png"

function Loader() {
  const { isDarkMode } = ThemeMode();
  const [addDeactivate, setDeactivateClass] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDeactivateClass(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`web-preloader ${addDeactivate ? 'deactivate' : ''}`}>
      <span className="web-inner">
        {isDarkMode ? (
          <img className="logo-icon" src={logoIcon} alt="" />
        ) : (
          <img className="logo-icon" src={logoIconDark} alt="" />
        )}
      </span>
    </div>
  );
}

export default Loader;