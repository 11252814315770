import React from 'react'

export default function ProgressBar({ className = "", barStyle = "barStyle1", label, percent, minvalue, maxvalue, current  }) {
  return (
    <>
      <div className={`common-progress-bx ${className}`}>
        {barStyle === 'barStyle1' ? (
          <>
            <div class="common-progress-data"><div className="cpd-label">{label}</div> <div className="cpd-value">{percent}%</div></div>
            <div className="common-progress-bar">
              <div className="common-progress-inner" style={{ width: `${percent}%` }}></div>
            </div>
          </>
        ) : barStyle === 'barStyle2' ? (
          <>
            <div class="common-progress-data">
              <div className="cpd-value">{minvalue}</div>
              <div className="cpd-value">{maxvalue}</div>
            </div>
            <div className="common-progress-bar cpb-of-none">
              <div className="common-progress-inner" style={{ width: `${percent}%` }}>
                <div className="common-progress-circle"></div>
              </div>
            </div>
            <div class="common-progress-data"><div className="cpd-current-value">{current}</div></div>
          </>
        ) : null}
      </div>
    </>
  )
}
