import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import Icons from '../../components/icons';
import Loaders from '../../components/Loader';

export default function WelcomeBox() {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const isInitialMount = useRef(true);
  const token = state.token || '';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall(API_URL + 'get-profile', {
          user_id: state.userData.id,
          token: token,
          nopaginate: 1,
        });
        if (response.data.success == '0') {
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
          setDataLoaded(true)
        } else {
          // console.log(response.data);
          console.error('Failed to fetch user details:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);


  return (
    <>
    {!dataLoaded && <Loaders />}
    <div className="welcome-bx">
        <div className="wb-heading">Welcome {firstName + " " + lastName}</div>
        <div className="wb-sub-heading">Are You Ready To Buy Your First Challenge?</div>
        <Link to="/new-challenge" className="common-btn cb-outline cb-wb-btn">Start Challenge<div className="cb-wb-icon"><Icons.RightArrowSvg /></div></Link>
    </div>
    </>
  )
}

